export const NC_CODES = [
  '4403',
  '4407',
  '4408',
  '4409',
  '9209',
  '010500',
  '010400',
  '010300',
  '030100'
]
export const CATEGORIES = [
  'FSC 100%',
  'FSC CW',
  'Madera Justa 100',
  'PEFC',
  'None',
  'PEFC + FSC'
]
export const TYPES = [
  'W1.1 Roundwood (logs)',
  'W5.2 Solid wood boards',
  'W5.4 Planks',
  'W6.1 Dimensional lumber, finished',
  'W7.2 Sliced veneer',
  'W7.3 Sawn veneer',
  'W13.5 Decking and garden sleepers',
  'W13.7 Other furniture and garden prod.',
  'W14.5 Parts of musical instruments',
  'N1 Barks',
  'W1.2 Fuel wood',
  'W3.1 Wood chips',
  'W3.2 Sawdust',
  'W3.3 Wood shavings',
  'W5.3 Beams',
  'W11.5 Flooring'
]
export const SPECIES = [
  { text: 'Ovangkol', value: 'Guibourtia ehie' },
  { text: 'Bigleaf Maple', value: 'Acer macrophyllum Pursh' },
  { text: 'European Maple', value: 'Acer pseudoplatanus' },
  { text: 'Pao Rosa', value: 'Bobgunia fistuloides' },
  { text: 'Spanish Cedar', value: 'Cedrela odorata' },
  { text: 'Indian Rosewood', value: 'Dalbergia latifolia' },
  { text: 'Blackwood', value: 'Dalbergia melanoxylon' },
  { text: 'Ebony', value: 'Diospyros crassiflora' },
  { text: 'Sapele', value: 'Entandrophragma cylindricum' },
  { text: 'Sipo', value: 'Entandrophragma utile' },
  { text: 'Bose', value: 'Guarea cedrata' },
  { text: 'Khaya', value: 'Khaya anthotheca' },
  { text: 'European Spruce', value: 'Picea abies' },
  { text: 'Sitka Spruce', value: 'Picea sitchensis' },
  { text: 'Mahogany', value: 'Swietenia macrophylla' },
  { text: 'Red Cedar', value: 'Thuja plicata' },
  { text: 'Padouk', value: 'Pterocarpus soyauxii' },
  { text: 'Wengue', value: 'Millettia laurentii' },
  { text: 'Indian Laurel', value: 'Ficus microcarpa' },
  { text: 'Machinche', value: 'Lonchocarputs castilloi Standl.' },
  { text: 'Pucte', value: 'Bacida buceras L.' },
  { text: 'Myrtlewood', value: 'Umbellularia californiana' },
  { text: 'Fromager', value: 'Ceiba penttandra' },
  { text: 'Ilomba', value: 'Pycnanthus angolensis' },
  { text: 'Okume', value: 'Aucomumea klaineana' },
  { text: 'Oak', value: 'Quercus rubra' },
  { text: 'Monkeypod', value: 'Albizia saman' },
  { text: 'Brazilian Rosewood', value: 'Dalbergia nigra' },
  { text: 'Dawn Redwood', value: 'Metasequoia glyptostruboides' },
  { text: 'Coast Redwood', value: 'Sequoia sempervirens' },
  { text: 'Bastogne walnut', value: 'J.hindsii x J. regia' },
  { text: 'Koa', value: 'Acacia koa' },
  { text: 'Rose Myrtle', value: 'Archirhodomyrtus beckleri' },
  { text: 'Ziricote', value: 'Cordia dodecandra' },
  { text: 'Nicaraguan rosewood', value: 'Dalbergia tucerensis' },
  { text: 'Cocobolo', value: 'Dalbergia retusa' },
  { text: 'Pinus sylvestris', value: 'Pino albar o Pinus sylvestris' },
  { text: 'Pinus nigra', value: 'Pino laricio o Pinus nigra' },
  { text: 'Pinus pinaster', value: 'Pino rodeno o Pinus pinaster' }
]

export const COUNTRIES = [
  { text: 'Belize', value: 'Belize' },
  { text: 'Brazil', value: 'Brazil' },
  { text: 'Cameroon', value: 'Cameroon' },
  { text: 'Gabon', value: 'Gabon' },
  { text: 'Guatemala', value: 'Guatemala' },
  { text: 'India', value: 'India' },
  { text: 'Republic of Congo', value: 'Republic of Congo' },
  { text: 'Romania', value: 'Romania' },
  { text: 'Tanzania', value: 'Tanzania' },
  { text: 'USA', value: 'USA' },
  { text: 'Australia', value: 'Australia' },
  { text: 'Nicaragua', value: 'Nicaragua' },
  { text: 'Cuenca', value: 'Cuenca' }
]

export const PARTS_CATEGORIES = [
  { value: 'back', text: 'Back', category: 'end_product' },
  { value: 'binding', text: 'Binding', category: 'end_product' },
  { value: 'bracing', text: 'Bracing', category: 'end_product' },
  { value: 'bridge', text: 'Bridge', category: 'end_product' },
  { value: 'fingerboard', text: 'Fingerboard', category: 'end_product' },
  { value: 'headplate', text: 'Headplate', category: 'end_product' },
  { value: 'heel', text: 'Heel', category: 'end_product' },
  { value: 'neck', text: 'Neck', category: 'end_product' },
  { value: 'purfling', text: 'Purfling', category: 'end_product' },
  { value: 'side', text: 'Side', category: 'end_product' },
  { value: 'top', text: 'Top', category: 'end_product' },
  { value: 'bark', text: 'Bark', category: 'end_product' },
  {
    value: 'firewood_and_biomass',
    text: 'Firewood and Biomass',
    category: 'end_product'
  },
  { value: 'wood_chips', text: 'Wood Chips', category: 'end_product' },
  { value: 'sawdust', text: 'Sawdust', category: 'end_product' },
  { value: 'shavings', text: 'Shavings', category: 'end_product' },
  { value: 'sawn_wood', text: 'Sawn Wood', category: 'end_product' },
  { value: 'beams', text: 'Beams', category: 'end_product' },
  {
    value: 'turned_sawn_wood',
    text: 'Turned Sawn Wood',
    category: 'end_product'
  },
  { value: 'pallet', text: 'Pallet', category: 'end_product' },
  { value: 'plank', text: 'Plank', category: 'end_product' }
]

export const USER_CATEGORIES = [
  { text: 'Supplier', value: 'supplier' },
  { text: 'Customer', value: 'customer' },
  { text: 'Forest Manager', value: 'forest_manager' },
  { text: 'Worker', value: 'worker' }
]

export const COMPANY_CATEGORIES = [
  { text: 'Supplier', value: 'supplier' },
  { text: 'Customer', value: 'customer' },
  { text: 'Forest Manager', value: 'forest_manager' }
]

export const INVENTORY_CATEGORIES = [
  { text: 'Log', value: 'log', category: 'raw_product' },
  {
    text: 'Log cut lengthwise',
    value: 'log_cut_lengthwise',
    category: 'raw_product'
  },
  { text: 'Lumber', value: 'lumber', category: 'raw_product' },
  {
    text: 'Lumber cut lengthwise',
    value: 'lumber_cut_lengthwise',
    category: 'raw_product'
  },
  { text: 'Billets/blocks', value: 'billets_blocks', category: 'raw_product' },
  { text: 'Wood Veneer', value: 'wood_veneer', category: 'raw_product' },
  {
    text: 'Item pending QC',
    value: 'item_pending_qc',
    category: 'raw_product'
  },
  { text: 'Drying', value: 'drying' },
  { text: 'Item', value: 'item', category: 'raw_product' },
  {
    text: 'Semi-finished product',
    value: 'semifinished_product',
    category: 'raw_product'
  },
  {
    text: 'Finished product pending QC',
    value: 'finished_product_pending_qc',
    category: 'raw_product'
  },
  {
    text: 'Finished product',
    value: 'finished_product',
    category: 'raw_product'
  },
  { text: 'Recuperation', value: 'recuperation', category: 'raw_product' },
  {
    text: 'Recuperate cut length and/or width',
    value: 'recuperate_cut_length'
  },
  { text: 'Waste', value: 'waste' },
  { text: 'Picked & Pack', value: 'pickedAndPack' }
]

export const ITEM_CATEGORIES = [
  { text: 'Resource', value: 'resource' },
  { text: 'Raw Product', value: 'raw_product' },
  { text: 'End Product', value: 'end_product' }
]

export const ITEM_UNITS_STATUS = [
  { text: 'Processing', value: 'processing' },
  { text: 'Pending QC', value: 'pending_quality_check' },
  { text: 'QC Finished', value: 'quality_check_finished' },
  { text: 'In Delivery', value: 'in_delivery' },
  { text: 'Delivered', value: 'delivered' },
  { text: 'Waiting for Delivery', value: 'waiting_for_delivery' }
]

export const DOCUMENTS_NAME = [
  'B/L',
  'Certificate of origin',
  'CITES',
  'Commercial invoice',
  'DUA',
  'Fumigation',
  'GPS picture',
  'Logistics costs invoice',
  'Packing List (PL)',
  'Phyto'
]

export const FACILITY_CATEGORIES = [{ text: 'Warehouse', value: 'warehouse' }]

export const INSTRUMENT_TYPES = ['Guitar', 'Piano', 'Violin']
