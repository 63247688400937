<template>
  <div>
    <v-radio-group v-model="finalVolumeUnit" row label="Deliver volume in:">
      <v-radio label="Cubic Meter" value="m³"></v-radio>
      <v-radio label="Board Feet" value="BF"></v-radio>
      <v-radio label="Cords" value="Cord"></v-radio>
    </v-radio-group>
    <v-row dense>
      <v-col v-if="finalVolumeUnit === 'BF'">
        <BaseFieldInput
          v-model.number="item.attributes.dimensions.length"
          :disabled="disabled"
          type="number"
          step="0.01"
          min="0"
          label="Length (ft.)"
          placeholder="0,00"
          @input="onChange"
      /></v-col>
      <v-col v-else>
        <BaseFieldInput
          v-model.number="item.attributes.dimensions.length"
          :disabled="disabled"
          type="number"
          step="0.01"
          min="0"
          :label="$t('item.length')"
          placeholder="0,00"
          @input="onChange"
      /></v-col>

      <v-col>
        <BaseFieldInput
          v-model.number="item.attributes.dimensions.width"
          :disabled="disabled"
          type="number"
          step="0.01"
          min="0"
          :label="$t('item.width')"
          placeholder="0,00"
          @input="onChange"
      /></v-col>
      <v-col>
        <BaseFieldInput
          v-model.number="item.attributes.dimensions.height"
          :disabled="disabled"
          type="number"
          min="0"
          step="1"
          :label="$t('item.thickness')"
          placeholder="0,00"
          @input="onChange"
        />
      </v-col>

      <v-col>
        <BaseFieldInput
          v-model.number="item.attributes.dimensions.diameter"
          type="number"
          min="0"
          :disabled="
            disabled ||
              !!item.attributes.dimensions.height ||
              !!item.attributes.dimensions.width ||
              finalVolumeUnit === 'BF' ||
              finalVolumeUnit === 'Cord'
          "
          step="0.01"
          :label="$t('item.diameter')"
          placeholder="0,00"
          @input="onChange"
      /></v-col>
      <v-col>
        <BaseUnitsSelect
          v-model="item.attributes.dimensionsUnit"
          :disabled="disabled || disableDimensionUnitSelector"
          name="dimension"
          :filters="['M', 'CM', 'MM', 'FT', 'IN']"
          @input="onChange"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col v-if="packOption" cols="4">
        <BaseFieldInput
          v-model.number="item.attributes.customAttributes.packQuantity"
          :disabled="disabled"
          min="1"
          type="number"
          step="1"
          :label="`Pack ${$t('item.quantity')}`"
          @input="onChange"
        />
      </v-col>
      <v-col cols="5">
        <BaseFieldInput
          v-model.number="item.attributes.volume"
          :disabled="disabled"
          type="number"
          min="0"
          step="0.000001"
          :label="$t('item.volume')"
          placeholder="0,000001"/></v-col
      ><v-col>
        <BaseUnitsSelect
          v-model="finalVolumeUnit"
          disabled
          name="volume"
          :filters="['CM', 'BF', 'CORD']"
          @input="onChange"
      /></v-col>
    </v-row>
  </div>
</template>

<script>
import { UnitsConversion } from '@/mixins/units.conversion.mixin'
export default {
  mixins: [UnitsConversion],
  props: {
    item: {
      type: Object,
      required: true
    },
    packOption: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      finalVolumeUnit: 'm³',
      disableDimensionUnitSelector: false
    }
  },
  watch: {
    finalVolumeUnit(unit) {
      this.item.attributes.volumeUnit = unit
      this.disableDimensionUnitSelector = false

      if (unit === 'BF') {
        this.item.attributes.dimensionsUnit = 'in'
        this.disableDimensionUnitSelector = true
      }
      if (unit === 'Cord') {
        this.item.attributes.dimensionsUnit = 'ft'
        this.disableDimensionUnitSelector = true
      }
    }
  },
  mounted() {
    this.finalVolumeUnit = this.item.attributes.volumeUnit
  },
  methods: {
    onChange() {
      let volume = 0
      const {
        dimensions: { length, width, height, diameter },
        dimensionsUnit,
        volumeUnit
      } = this.item.attributes

      if (this.finalVolumeUnit === 'BF')
        this.item.attributes.dimensionsUnit = 'in'

      const lengthM = this.toMeters(length, dimensionsUnit)
      const widthM = this.toMeters(width, dimensionsUnit)
      const heightM = this.toMeters(height, dimensionsUnit)
      const diameterM = this.toMeters(diameter, dimensionsUnit)

      if (width || height) {
        this.item.attributes.dimensions.diameter = null
        volume = this.rectangleVolume(lengthM, widthM, heightM)
      } else if (diameter) volume = this.cilinderVolume(diameterM / 2, lengthM)

      if (volumeUnit === 'Cord') {
        // Cords only accepts ft. units for dimensions
        const volumeInFeet = this.rectangleVolume(length, width, height)
        volume = this.toCords(volumeInFeet, 'ft')
      }

      if (volumeUnit === 'BF') {
        // Cords only accepts ft. units for length and in. for widht and height
        volume = this.boardFootVolume(length, width, height)
      }

      const packQuantity =
        this.item.attributes?.customAttributes?.packQuantity || 1
      this.item.attributes.volume = this.roundNumber(volume * packQuantity)
    }
  }
}
</script>
